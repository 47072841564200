import { useState } from 'react';
import LinkNext from 'next/link';
import { oneLine } from 'common-tags';

import { Link } from '@/atoms';

import type { TypeTextColorVariant } from '@/components/types';
import type { FunctionComponent } from 'react';
import type { TypeAnchorMenuProps } from './types';

const VARIANT_MAPS: Record<TypeTextColorVariant, string> = {
  primary: ' text-primary-400 scrollbar-transparente hover:scrollbar-primary',
  secondary:
    ' text-primary-400 scrollbar-transparente hover:scrollbar-secondary',
  alternative: 'bg-white scrollbar-transparente hover:scrollbar-secondary',
};

/**
 * AnchorMenu
 */
export const AnchorMenu: FunctionComponent<TypeAnchorMenuProps> = ({
  className = '',
  element,
  variant,
}: TypeAnchorMenuProps) => {
  const [active, setActive] = useState('');

  return (
    <div
      className={`${VARIANT_MAPS[variant]} flex flex-row overflow-auto ${className}`}
    >
      {element.map((child, index) => (
        <LinkNext href={child.link} key={index} passHref prefetch={false}>
          <Link
            className={oneLine`
              flex-shrink-0 border-b-[2px] border-primary-200 p-5 pb-4
              ${variant === 'alternative' ? '!text-black' : ''}
              ${
                active === child.link
                  ? 'border-b-[2px] border-primary-600 font-bold'
                  : 'text-secondary font-medium !text-primary-300'
              } cursor-pointer text-[18px] `}
            variant={variant}
            onClick={() => setActive(child.link)}
          >
            {child.label}
          </Link>
        </LinkNext>
      ))}
    </div>
  );
};

AnchorMenu.displayName = 'AnchorMenu';
