import { AnchorMenu as AnchorMenuBase, Container } from '@/atoms';

import type { TypeTextColorVariant } from '@/components/types';
import type { FunctionComponent } from 'react';
import type { TypeAnchorMenuProps } from './types';

const VARIANT_MAPS: Record<TypeTextColorVariant, string> = {
  primary: ' text-primary-400 ',
  secondary: ' text-primary-400',
  alternative: 'bg-white ',
};

/**
 * AnchorMenu
 */
export const AnchorMenu: FunctionComponent<TypeAnchorMenuProps> = ({
  className = '',
  backgroundColorBottom,
  backgroundColorBottomMobile,
  backgroundColorTop,
  backgroundColorTopMobile,
  element,
  variant,
  index,
  hasCutLevel,
}: TypeAnchorMenuProps) => (
  <Container
    index={index}
    backgroundColorBottom={backgroundColorBottom}
    backgroundColorTopMobile={backgroundColorTopMobile}
    backgroundColorTop={backgroundColorTop}
    backgroundColorBottomMobile={backgroundColorBottomMobile}
    hasCutLevel={hasCutLevel}
    overflows
    className={` !py-1  ${VARIANT_MAPS[variant]} ${className}`}
  >
    <AnchorMenuBase element={element} variant={variant} />
  </Container>
);

AnchorMenu.displayName = 'AnchorMenu';
